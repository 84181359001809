import { Form } from '@valid-eval/shared-react-components';
import { Controller } from 'react-hook-form';

import { required as requiredVal } from 'components/FormFields/validations';

import FieldError from './FieldError';
import HelpText from './HelpText';
import Label from './Label';
import useFormFieldState from './useFormFieldState';

export type RadioButtonsProps = {
  id?: string;
  label?: string | React.ReactNode;
  name: string;
  rules?: { validate?: Record<string, any> };
  disabled?: boolean;
  required?: boolean;
  helpText?: string;
  options: { value: string; label: string }[];
};

const RadioButtons = ({
  id,
  label,
  name,
  rules,
  disabled,
  required,
  helpText,
  options,
}: RadioButtonsProps) => {
  const { control, isValid, isInvalid, error, validationClassname } = useFormFieldState(name);

  rules = rules || {};
  rules = required ? { ...rules, validate: { required: requiredVal } } : rules;

  const fieldId = `${name || id}-checkbox`;

  return (
    <>
      <Form.Group className="mb-2">
        <Label htmlFor={fieldId} label={label} />
        <HelpText text={helpText} />
        {options.map((option, index) => (
          <Controller
            key={option.value}
            control={control}
            name={`${name}`}
            rules={rules}
            render={({ field: { value, onChange, ...fieldProps } }) => {
              return (
                <Form.Check
                  id={`${fieldProps.name}.${index}`}
                  className={validationClassname}
                  label={option.label}
                  isInvalid={isInvalid}
                  isValid={isValid}
                  disabled={disabled}
                  type={'radio'}
                  onChange={(e) => onChange(e.target.checked ? option.value : '')}
                  checked={value === option.value}
                  value={value ?? ''}
                  {...fieldProps}
                />
              );
            }}
          />
        ))}
        <FieldError show={isInvalid} message={String(error?.message)} />
      </Form.Group>
    </>
  );
};

export default RadioButtons;
