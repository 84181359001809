import { Col, Form, Row } from '@valid-eval/shared-react-components';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';

import DynamicFields from 'components/FormFields/DynamicFields';

import { StepComponentProps } from '../types';
import StepContainer from './StepContainer';
import StepFooter from './StepFooter';
import useCategoriesForm, { CategoriesFormType } from './useCategoriesForm';
import useSaveTeamDraft from './useSaveTeamDraft';
import { t } from 'i18next';

type CategoriesStepProps = StepComponentProps;

const CategoriesStep = ({
  draft,
  event,
  onInfoChange,
  onNoSession,
  onSubmitStep,
  user,
  saving,
  isActive,
  isTeamMemberEditor,
}: CategoriesStepProps) => {
  const { form, getDraftWithCategories } = useCategoriesForm({
    draft,
    event,
    onInfoChange,
    user,
    isActive,
  });
  const saveTeamDraft = useSaveTeamDraft({ eventId: event?.id || '', onNoSession });

  useEffect(() => {
    if (!draft?.id) return;

    const subscription = form.watch((_, info) => {
      if (info.type !== 'change') return;

      const data = form.getValues();
      saveTeamDraft(getDraftWithCategories(draft, data), true, () => form.trigger());
    });

    return () => subscription.unsubscribe();
  }, [draft, getDraftWithCategories]);

  const handleSubmit = (data: CategoriesFormType) => {
    if (!draft?.id) return;
    saveTeamDraft(getDraftWithCategories(draft, data), true, onSubmitStep);
  };

  return (
    <FormProvider {...form}>
      <Form className="h-100" onSubmit={form.handleSubmit(handleSubmit)} noValidate>
        <StepContainer
          content={
            <>
              <p>{t('auth.teams_sign_up.steps.categories.description')}</p>
              <Row>
                <DynamicFields
                  fields={event?.categories || []}
                  fieldWrapper={<Col sm={6} className="mb-3" />}
                  disabled={!user?.id || !draft?.id || !isTeamMemberEditor}
                />
              </Row>
            </>
          }
          footer={
            isTeamMemberEditor && (
              <StepFooter
                event={event}
                draft={draft}
                active={isActive}
                disabled={saving || form.formState.isSubmitting || !user?.id || !draft?.id}
                loading={saving || form.formState.isSubmitting}
              />
            )
          }
        />
      </Form>
    </FormProvider>
  );
};

export default CategoriesStep;
