import { Map } from 'immutable';
import { connect, ConnectedProps } from 'react-redux';

import { success as showSuccess } from 'data/actions/notifications';
import { fromNotes } from 'data/features/notes';
import { Note } from 'data/features/notesTypes';

import { useState } from 'react';
import NoteForm, { NoteFormData } from './components/NoteForm';
import useSaveNote from './hooks/useSaveNote';

type AddNoteProps = {
  isOrganizerScreen: boolean;
  dimensionId?: string;
  phaseId: string;
  teamId: string;
  compact?: boolean;
  onNoteAdded?(): void;
  withButton?: boolean;
  initialShow?: boolean;
};

const mapStateToProps = (state: Map<string, any>, ownProps: AddNoteProps) => ({
  saving: fromNotes.getIsSaving(state?.toJS()),
  canAddInternalNotes: fromNotes.getCanAddInternalNotes(state?.toJS(), ownProps.teamId),
  canAddSharedNotes: fromNotes.getCanAddSharedNotes(state?.toJS(), ownProps.teamId),
});

const mapDispatchToProps = {
  showSuccess,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const AddNote = ({
  isOrganizerScreen = false,
  saving,
  phaseId,
  teamId,
  dimensionId,
  canAddSharedNotes,
  canAddInternalNotes,
  compact,
  showSuccess,
  onNoteAdded,
  withButton = true,
  initialShow = false,
}: AddNoteProps & ConnectedProps<typeof connector>) => {
  const [note, setNote] = useState<Note | undefined>(undefined);
  const saveNote = useSaveNote({ phaseId, teamId, dimensionId, isOrganizerScreen });

  const handleSubmit = async (data: NoteFormData, dirtyFields: Record<string, boolean>) => {
    const result = await saveNote(data, dirtyFields);

    if (result) {
      showSuccess('The comment has been saved');
      onNoteAdded?.();
      setNote(data.autosaved ? result.payload?.note : undefined);
    }

    return result;
  };

  const handleDelete = () => {
    setNote(undefined);
  };

  if (!canAddSharedNotes && !canAddInternalNotes) return null;

  return (
    <NoteForm
      isAddForm
      note={note}
      initialShow={initialShow}
      onSubmit={handleSubmit}
      saving={saving}
      onDelete={handleDelete}
      internalOnly={!canAddSharedNotes}
      compact={compact}
      withButton={withButton}
    />
  );
};

export default connector(AddNote);
