import { Button, Form } from '@valid-eval/shared-react-components';
import parse from 'html-react-parser';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { NavigateFunction } from 'react-router';

import RadioButtons from 'components/Form/RadioButtons';
import GenericModal from 'components/GenericModal';
import { Event } from 'data/features/eventsTypes';
import { deleteTeamDraft, fromTeamDrafts } from 'data/features/teamDrafts';
import { TeamDraft } from 'data/features/teamDraftTypes';
import withRouter from 'routes/withRouter';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';
import { useShowErrorOnRejectedWithValue } from 'utils/hooks/useShowErrorOnRejectedWithValue';
import { teamSignUp } from 'utils/urls';
import { success as showSuccess } from 'data/actions/notifications';

type OwnProps = {
  draft?: TeamDraft;
  event?: Event;
  navigate: NavigateFunction;
};

type DeleteDraftFormData = {
  reason: string;
};

const mapDispatchToProps = { deleteTeamDraft, showSuccess };

const mapStateToProps = (state: any) => ({
  saving: fromTeamDrafts.getIsSaving(state?.toJS()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type DeleteDraftButtonProps = OwnProps & ConnectedProps<typeof connector>;

const DeleteDraftButton = ({
  deleteTeamDraft,
  draft,
  event,
  navigate,
  saving,
  showSuccess,
}: DeleteDraftButtonProps) => {
  const { t } = useTranslation();
  const showErrorOnRejectedWithValue = useShowErrorOnRejectedWithValue();

  const form = useForm<DeleteDraftFormData>({ mode: 'onBlur' });
  const [showConfirmation, openConfirmation, closeConfirmation] = useBooleanFlag();

  if (!event?.team_profile_deletion_reasons || !draft?.id) return null;

  const onSubmit = async (data: DeleteDraftFormData) => {
    const result = await deleteTeamDraft({ teamId: draft.id, reason: data.reason });
    if (!showErrorOnRejectedWithValue(result)) return;

    closeConfirmation();
    showSuccess(t('auth.teams_sign_up.delete_draft.success'));
    navigate(teamSignUp(event.slug));
  };

  return (
    <>
      <FormProvider {...form}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.handleSubmit(onSubmit)();
          }}
          onChange={(e) => e.stopPropagation()}
        >
          <GenericModal
            body={
              <>
                <p>{parse(t('auth.teams_sign_up.delete_draft.confirmation_body', draft))}</p>
                <RadioButtons
                  label={<b>{t('auth.teams_sign_up.delete_draft.confirmation_reason')}</b>}
                  name="reason"
                  options={event.team_profile_deletion_reasons}
                  required
                />
              </>
            }
            isButtonDisabled={saving}
            isCancelButtonDisabled={saving}
            name="delete-draft-confirmation"
            onCancel={closeConfirmation}
            onConfirm={form.handleSubmit(onSubmit)}
            show={showConfirmation}
            size="md"
            title={t('auth.teams_sign_up.delete_draft.confirmation_title')}
            danger
          />
        </Form>
      </FormProvider>
      <Button id="delete-draft-button" onClick={openConfirmation} variant="link-danger">
        {t('auth.teams_sign_up.delete_draft.button')} <i className="fa-regular fa-trash-can ms-2" />
      </Button>
    </>
  );
};

export default withRouter(connector(DeleteDraftButton));
