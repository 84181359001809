import { Col, Form, Row } from '@valid-eval/shared-react-components';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';

import ArtifactsFields from 'components/Form/ArtifactsFields';
import { ArtifactItem } from 'data/features/artifactItemsTypes';
import { useDebouncedCallback } from 'utils/debounce-promise';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';

import { StepComponentProps } from '../types';
import ConfirmDeleteArtifactItemModal from './ConfirmDeleteArtifactItemModal';
import StepContainer from './StepContainer';
import StepFooter from './StepFooter';
import useSaveArtifactItems from './useSaveArtifactItems';
import useUploadsForm from './useUploadsForm';

const UploadsStep = ({
  artifactItems,
  draft,
  event,
  onInfoChange,
  onNoSession,
  savingArtifactItems,
  user,
  isActive,
  isTeamMemberEditor,
}: StepComponentProps) => {
  const { form } = useUploadsForm({ event, user, draft, artifactItems, onInfoChange });
  const saveArtifactItems = useSaveArtifactItems({
    eventId: event?.id || '',
    teamId: draft?.id || '',
    onNoSession,
  });
  const [showConfirmDelete, openConfirmDelete, closeConfirmDelete] = useBooleanFlag();
  const [artifactItemToDelete, setArtifactItemToDelete] = useState<ArtifactItem | null>(null);

  useEffect(() => {
    const subscription = form.watch((_, info) => {
      if (info.type !== 'change') return;

      savePartial();
    });

    return () => subscription.unsubscribe();
  }, [event?.id, draft?.id]);

  const save = async (data: Record<string, string>) => {
    if (!event?.id || !draft?.id) return;

    // Filter out invalid fields and non-url artifacts
    const artifactItems = Object.keys(data).reduce<Record<string, string>>((acc, key) => {
      const field = form.getFieldState(key);
      if (field.invalid) return acc;

      const artifact = event?.artifacts.find((a) => String(a.id) === key);
      if (!artifact || artifact.type !== 'url') return acc;

      acc[key] = data[key];
      return acc;
    }, {});

    if (Object.values(artifactItems).length === 0) return;

    saveArtifactItems(artifactItems, form.trigger);
  };

  const savePartial = useDebouncedCallback(
    async () => save(form.getValues()),
    [event?.id, draft?.id, Object.keys(form.formState.errors).toString()],
    500,
  );

  const saveArtifactItem = async (name: string, url: string) => {
    if (!event?.id || !draft?.id) return;
    saveArtifactItems({ [name]: url }, form.trigger);
  };

  const handleDelete = (value: ArtifactItem) => {
    setArtifactItemToDelete(value);
    openConfirmDelete();
  };

  const handleConfirmDelete = () => {
    closeConfirmDelete();
    if (!artifactItemToDelete) return;

    saveArtifactItem(String(artifactItemToDelete.artifact.id), '');
  };

  return (
    <>
      <ConfirmDeleteArtifactItemModal
        show={showConfirmDelete}
        onClose={closeConfirmDelete}
        onConfirm={handleConfirmDelete}
        artifactItem={artifactItemToDelete}
      />
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(save)}>
          <StepContainer
            content={
              <Row>
                <ArtifactsFields
                  artifactItems={artifactItems || {}}
                  artifacts={event?.artifacts || []}
                  onDelete={handleDelete}
                  onFinishUpload={saveArtifactItem}
                  onNoSession={onNoSession}
                  signingUrlQueryParams={{
                    event_id: event?.id || '',
                    team_id: draft?.id || '',
                  }}
                  fieldWrapper={<Col xs={12} className="mb-3" />}
                  disabled={!user?.id || !draft?.id || !isTeamMemberEditor}
                />
              </Row>
            }
            footer={
              isTeamMemberEditor && (
                <StepFooter
                  event={event}
                  draft={draft}
                  active={isActive}
                  disabled={
                    savingArtifactItems || form.formState.isSubmitting || !user?.id || !draft?.id
                  }
                  loading={savingArtifactItems || form.formState.isSubmitting}
                />
              )
            }
          />
        </Form>
      </FormProvider>
    </>
  );
};

export default UploadsStep;
