import { ReactNode } from 'react';
import { Form } from '@valid-eval/shared-react-components';

export type LabelProps = {
  htmlFor?: string;
  label?: string | ReactNode;
};

const Label = ({ htmlFor, label }: LabelProps) => {
  return label ? (
    <Form.Label className="w-100" htmlFor={htmlFor}>
      {label}
    </Form.Label>
  ) : null;
};

export default Label;
