import { Button, Form, OverlayTrigger, Tooltip } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { useCallback, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';

import Checkbox from 'components/Form/Checkbox';
import TextArea from 'components/Form/TextArea';
import { Note } from 'data/features/notesTypes';

import NoteDelete, { NoteDeleteRef } from './NoteDelete';
import Styles from './NoteForm.module.scss';
import NoteFormWrapper from './NoteFormWrapper';
import { isTeamLead } from 'utils';
import { isGovEnv } from 'config';
import { useTranslation } from 'react-i18next';

const isInternal = (note?: Note, internalOnly?: boolean): boolean =>
  Boolean(note?.type === 'internal' || internalOnly);

export type NoteFormData = {
  id?: string;
  content: string;
  internal: boolean;
  updated_at?: string;
  autosaved?: boolean;
};

type NoteFormProps = {
  isAddForm?: boolean;
  internalOnly?: boolean;
  note?: Note;
  onDelete?(): void;
  onCancel?(): void;
  onSubmit(data: NoteFormData, dirtyFields: Record<string, boolean>): Promise<Note | undefined>;
  saving?: boolean;
  withButton?: boolean;
  compact?: boolean;
  initialShow?: boolean;
};

const NoteForm = ({
  isAddForm,
  compact,
  internalOnly,
  note,
  onCancel,
  onDelete,
  onSubmit,
  saving,
  withButton = true,
  initialShow = false,
}: NoteFormProps) => {
  const { t } = useTranslation();
  const deleteRef = useRef<NoteDeleteRef | null>(null);

  const form = useForm<NoteFormData>({
    values: {
      id: note?.id,
      content: note?.content ?? '',
      internal: isInternal(note, internalOnly),
      updated_at: note?.updatedAt,
    },
    mode: 'onBlur',
  });

  const { id, internal } = form.getValues();

  // If only internal notes are allowed, set the internal checkbox to true
  const resetForm = () => {
    form.reset();
    if (internalOnly) form.setValue('internal', true);
    form.trigger();
  };

  const onShowForm = () => {
    form.setFocus('content');
  };

  const handleSubmit = async (data: NoteFormData) => {
    const result = await onSubmit(data, form.formState.dirtyFields);
    if (!result) return;
    resetForm();
  };

  const handleAutosave = (data: NoteFormData) => {
    data.autosaved = true;
    handleSubmit(data);
  };

  const debouncedSave = useCallback(
    debounce(() => form.handleSubmit(handleAutosave)(), 3e3),
    [],
  );

  const debouncedDelete = useCallback(
    debounce(() => deleteRef.current?.openDeleteConfirmation(), 2e3),
    [deleteRef.current],
  );

  const handleManualSave = (data: NoteFormData) => {
    debouncedSave.cancel();
    data.autosaved = false;
    handleSubmit(data);
  };

  const handleDelete = () => {
    onDelete?.();
    resetForm();
  };

  // Auto focus the content field if no button is shown
  useEffect(() => {
    if (!withButton) onShowForm();
  }, []);

  // Detects if there is a change at the comment box
  // and asks the user for confirmation before leaving
  // the page.
  // NOTE THE DEFAULT BROWSER MESSAGE CANNOT BE OVERRIDDEN
  useEffect(() => {
    function unloadListener(e: BeforeUnloadEvent) {
      if (form.formState.isValid) {
        const confirmationMessage = '/o';
        e.returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Webkit, Safari, Chrome
      }
      return true;
    }

    window.addEventListener('beforeunload', unloadListener);
    return () => window.removeEventListener('beforeunload', unloadListener);
  }, [form.formState.isValid]);

  // If note id changes, set the form values
  useEffect(() => {
    form.setValue('id', note?.id);
    form.setValue('content', note?.content ?? '');
    form.setValue('internal', isInternal(note, internalOnly));
    form.setValue('updated_at', note?.updatedAt);
    form.trigger();
  }, [note?.id]);

  // If note updatedAt changes only update the toggle and updated_at to prevent
  // loosing the text the user has entered
  useEffect(() => {
    if (note && form.getValues().updated_at !== note.updatedAt) {
      form.setValue('internal', isInternal(note, internalOnly));
      form.setValue('updated_at', note.updatedAt);
      form.trigger();
    }
  }, [note?.updatedAt]);

  useEffect(() => {
    resetForm();
  }, [internalOnly]);

  // Listen for changes to the form and trigger autosave or delete
  useEffect(() => {
    const { unsubscribe } = form.watch(({ id, content }, { type }) => {
      if (type === 'change') {
        debouncedSave();
        id && !content && isAddForm ? debouncedDelete() : debouncedDelete.cancel();
      }
    });
    return () => unsubscribe();
  }, [form.watch]);

  const disableEditContentForTeamLead = note && isTeamLead() && !note?.isYou;

  return (
    <NoteFormWrapper
      internal={internal}
      withButton={withButton}
      onShowForm={onShowForm}
      initialShow={initialShow}
    >
      <div>
        <FormProvider {...form}>
          <Form onSubmit={form.handleSubmit(handleManualSave)}>
            <TextArea
              placeholder="Leave your comment here..."
              required
              name="content"
              spellCheck={true}
              className={cx({
                [Styles.TextAreaInternal]: internal,
                [Styles.TextAreaShared]: !internal,
              })}
              disabled={saving || disableEditContentForTeamLead}
            />
            <div className="mt-3 d-flex justify-content-end align-items-center">
              <div className="flex-grow-1">
                {note && (
                  <NoteDelete
                    ref={deleteRef}
                    note={note}
                    isAddForm={isAddForm}
                    onDelete={handleDelete}
                  />
                )}
              </div>

              <div
                className={cx('d-flex align-items-center justify-content-end flex-grow-1 ms-4', {
                  'me-2': !compact || !id || isAddForm,
                })}
              >
                <OverlayTrigger
                  placement="top"
                  show={!internalOnly ? false : undefined}
                  overlay={<Tooltip>{t('notes.alerts.viewed')}</Tooltip>}
                >
                  <div className="d-inline-block">
                    <Checkbox
                      disabled={internalOnly || saving}
                      name="internal"
                      showValidationState={false}
                      isSwitch
                    />
                  </div>
                </OverlayTrigger>
                <small className={cx('text-muted fw-bold')}>
                  {isGovEnv() ? 'Government-only' : 'Selection committee'}
                </small>
              </div>

              {id && !isAddForm && (
                <Button
                  className={cx({
                    'me-2': !compact,
                    'px-3': compact,
                    'py-2': compact,
                  })}
                  variant="link"
                  type="button"
                  disabled={saving}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="success"
                type="submit"
                className={cx({
                  'px-3': compact,
                  'py-2': compact,
                })}
                disabled={saving}
              >
                {id ? 'Save' : 'Add Comment'}
              </Button>
            </div>
          </Form>
        </FormProvider>
      </div>
    </NoteFormWrapper>
  );
};

export default NoteForm;
