import cx from 'classnames';
import { Map } from 'immutable';
import { connect, ConnectedProps } from 'react-redux';

import { success as showSuccess } from 'data/actions/notifications';
import { fromNotes } from 'data/features/notes';
import { Note as NoteType } from 'data/features/notesTypes';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';

import useSaveNote from '../hooks/useSaveNote';
import Styles from './Note.module.scss';
import NoteDelete from './NoteDelete';
import NoteEdit from './NoteEdit';
import NoteForm, { NoteFormData } from './NoteForm';
import NoteHeader from './NoteHeader';
import NoteVotes from './NoteVotes';

type NoteProps = {
  note: NoteType;
  isFirstInternal: boolean;
  isOrganizerScreen?: boolean;
  compact?: boolean;
  phaseId: string;
  teamId: string;
  showDimensionName?: boolean;
};

const mapStateToProps = (state: Map<string, any>, ownProps: NoteProps) => ({
  saving: fromNotes.getIsSaving(state?.toJS()),
  canAddSharedNotes: fromNotes.getCanAddSharedNotes(state?.toJS(), ownProps.teamId),
});

const mapDispatchToProps = {
  showSuccess,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const Note = ({
  canAddSharedNotes,
  isFirstInternal,
  isOrganizerScreen,
  note,
  phaseId,
  teamId,
  showSuccess,
  compact,
  showDimensionName,
  saving,
}: NoteProps & ConnectedProps<typeof connector>) => {
  const [isEditing, edit, cancelEdit] = useBooleanFlag();
  const saveNote = useSaveNote({ phaseId, teamId, isOrganizerScreen: !!isOrganizerScreen });

  const handleSubmit = async (data: NoteFormData, dirtyFields: Record<string, boolean>) => {
    const result = await saveNote(data, dirtyFields);
    if (result) showSuccess('The comment has been saved');
    if (result && !data.autosaved) cancelEdit();

    return result;
  };

  return (
    <div
      id={`note-${note.id}`}
      className={cx('px-3 py-2 rounded mb-3', {
        [Styles.NoteInternal]: note.type === 'internal',
        [Styles.NoteShared]: note.type === 'shared' && !note.writerIsOrganizer,
        [Styles.NoteSharedByOrganizer]: note.type === 'shared' && note.writerIsOrganizer,
      })}
    >
      <NoteHeader
        note={note}
        isFirstInternal={isFirstInternal}
        showDimensionName={showDimensionName}
        isOrganizerScreen={isOrganizerScreen}
      />
      {isEditing ? (
        <NoteForm
          internalOnly={!canAddSharedNotes}
          note={note}
          onCancel={cancelEdit}
          onSubmit={handleSubmit}
          withButton={false}
          saving={saving}
          compact={compact}
        />
      ) : (
        <p className="fw-bold text-pre-wrap">
          <small>{note.content}</small>
        </p>
      )}
      {!isEditing && (
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-end">
            <NoteVotes note={note} />
          </div>
          <div className="d-flex align-items-start">
            <NoteEdit note={note} onClick={edit} disabled={saving} />
            <NoteDelete note={note} disabled={saving} />
          </div>
        </div>
      )}
    </div>
  );
};

export default connector(Note);
