import { Button } from '@valid-eval/shared-react-components';
import { forwardRef, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';

import { ThunkDispatch } from '@reduxjs/toolkit';
import GenericModal from 'components/GenericModal';
import { success as showSuccess } from 'data/actions/notifications';
import { deleteNote } from 'data/features/notes';
import { Note as NoteType } from 'data/features/notesTypes';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';
import { useShowErrorOnRejectedWithValue } from 'utils/hooks/useShowErrorOnRejectedWithValue';

type NoteDeleteProps = {
  isAddForm?: boolean;
  note: NoteType;
  disabled?: boolean;
  onDelete?(): void;
};

export type NoteDeleteRef = {
  openDeleteConfirmation(): void;
};

const NoteDelete = forwardRef<NoteDeleteRef, NoteDeleteProps>(
  ({ note, disabled, isAddForm, onDelete }, ref) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const [showConfirmation, openConfirmation, closeConfirmation] = useBooleanFlag();
    const showErrorOnRejectedWithValue = useShowErrorOnRejectedWithValue();

    useImperativeHandle(ref, () => ({
      openDeleteConfirmation: openConfirmation,
    }));

    const handleDelete = async () => {
      closeConfirmation();
      const result = showErrorOnRejectedWithValue(
        await dispatch(
          deleteNote({
            noteId: note.id,
            phaseId: note.phaseId,
            teamId: note.teamId,
          }),
        ),
      );
      if (result) {
        dispatch(showSuccess('The comment has been deleted'));
        onDelete?.();
      }
      return result;
    };

    return (
      <>
        {note.canDelete && (
          <>
            <GenericModal
              body="Please confirm that you want to delete this comment?"
              name="delete-note"
              onCancel={closeConfirmation}
              onConfirm={handleDelete}
              show={showConfirmation}
              title="Delete Comment"
              size="md"
            />
            {!isAddForm && (
              <Button
                name="delete"
                variant="link"
                className="p-0"
                onClick={openConfirmation}
                disabled={disabled}
              >
                <i className="m-0 fa-regular fa-trash-can" />
              </Button>
            )}
          </>
        )}
      </>
    );
  },
);

export default NoteDelete;
