import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit';

import { filterNotes, sortNotes, transformNotes } from 'components/NotesV2/helpers';
import { del, get, post, put } from 'data/services/config';

import { Note, NotePayload, NotesFlags } from './notesTypes';

export const notesAdapter = createEntityAdapter<Note>();

const initialState = notesAdapter.getInitialState({
  notesFlagsByTeamId: {} as Record<string, NotesFlags>,
  canAddInternalNotes: false,
  canAddSharedNotes: false,
  saving: false,
  loading: false,
});

export const fetchNotes = createAsyncThunk(
  'notes/fetch',
  async (
    { phaseId, teamId, role }: { phaseId: string; teamId: string; role: string },
    { rejectWithValue },
  ) => {
    try {
      return await get(`/phases/${phaseId}/teams/${teamId}/notes?for=${role}`);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createNote = createAsyncThunk(
  'notes/create',
  async (
    {
      note,
      phaseId,
      teamId,
    }: { note: NotePayload; phaseId: string; teamId: string; autosaved?: boolean },
    { rejectWithValue },
  ) => {
    try {
      const { dimensionId, ...notePayload } = note;
      return await post(`/phases/${phaseId}/teams/${teamId}/notes`, {
        note: {
          dimension_id: dimensionId,
          ...notePayload,
        },
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateNote = createAsyncThunk(
  'notes/update',
  async (
    {
      phaseId,
      teamId,
      note,
    }: { phaseId: string; teamId: string; note: NotePayload; autosaved?: boolean },
    { rejectWithValue },
  ) => {
    try {
      return await put(`/phases/${phaseId}/teams/${teamId}/notes/${note.id}`, { note });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteNote = createAsyncThunk(
  'notes/delete',
  async (
    { noteId, phaseId, teamId }: { noteId: string; phaseId: string; teamId: string },
    { rejectWithValue },
  ) => {
    try {
      return await del(`/phases/${phaseId}/teams/${teamId}/notes/${noteId}`, {});
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const voteNote = createAsyncThunk(
  'notes/vote',
  async (
    {
      voteValue,
      noteId,
      phaseId,
      teamId,
    }: {
      voteValue: number;
      noteId: string;
      phaseId: string;
      teamId: string;
    },
    { rejectWithValue },
  ) => {
    try {
      return await post(`/phases/${phaseId}/teams/${teamId}/notes/${noteId}/vote`, {
        note: { vote_value: voteValue },
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const evaluationViewed = createAsyncThunk(
  'notes/evaluationViewed',
  async ({ evaluationId }: { evaluationId: string }, { rejectWithValue }) => {
    try {
      return await post(`/evaluations/${evaluationId}/viewed`, {});
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const notesSlice = createSlice({
  name: 'notesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNotes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchNotes.fulfilled, (state, action) => {
      notesAdapter.setMany(state, action.payload.notes);
      state.notesFlagsByTeamId = {
        ...state.notesFlagsByTeamId,
        [action.meta.arg.teamId]: {
          canAddInternalNotes: action.payload.can_add_internal_notes,
          canAddSharedNotes: action.payload.can_add_shared_notes,
        },
      };
    });
    builder.addCase(deleteNote.fulfilled, (state, action) => {
      notesAdapter.removeOne(state, action.meta.arg.noteId);
    });
    builder.addMatcher(
      isAnyOf(voteNote.fulfilled, createNote.fulfilled, updateNote.fulfilled),
      (state, action) => {
        if (
          'autosaved' in action.meta.arg &&
          action.meta.arg.autosaved &&
          action.type !== updateNote.fulfilled.type
        )
          return;
        notesAdapter.upsertOne(state, action.payload.note);
      },
    );
    builder.addMatcher(isAnyOf(fetchNotes.rejected, fetchNotes.fulfilled), (state) => {
      state.loading = false;
    });

    builder.addMatcher(
      isAnyOf(createNote.pending, voteNote.pending, deleteNote.pending, updateNote.pending),
      (state, action) => {
        if ('autosaved' in action.meta.arg && action.meta.arg.autosaved) return;
        state.saving = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        updateNote.fulfilled,
        updateNote.rejected,
        createNote.fulfilled,
        createNote.rejected,
        deleteNote.fulfilled,
        deleteNote.rejected,
        voteNote.fulfilled,
        voteNote.rejected,
      ),
      (state) => {
        state.saving = false;
      },
    );
  },
  selectors: {
    getIsSaving: (state) => state.saving,
    getIsLoading: (state) => state.loading,
    getCanAddInternalNotes: (state, teamId: string) =>
      state.notesFlagsByTeamId[teamId]?.canAddInternalNotes ?? false,
    getCanAddSharedNotes: (state, teamId: string) =>
      state.notesFlagsByTeamId[teamId]?.canAddSharedNotes ?? false,
    getOverallNotes: createSelector(
      notesAdapter.getSelectors().selectAll,
      (_, params) => params,
      (notes, params) => transformNotes(sortNotes(filterNotes(notes, params)), params),
    ),
    getDimensionNotes: createSelector(
      notesAdapter.getSelectors().selectAll,
      (_, params) => params,
      (notes, params) => transformNotes(sortNotes(filterNotes(notes, params)), params),
    ),
  },
});

export const fromNotes = notesSlice.selectors;

export default notesSlice.reducer;
