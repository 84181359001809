import { Button, OverlayTrigger, Tooltip } from '@valid-eval/shared-react-components';
import cx from 'classnames';
import { Map } from 'immutable';
import { useEffect, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { fromNotes } from 'data/features/notes';
import useBooleanFlag from 'utils/hooks/useBooleanFlag';
import useResizeObserver from 'utils/hooks/useResizeObserver';

import AddNote from './AddNote';
import Styles from './components/DimensionNotes.module.scss';
import NotesList from './NotesList';

interface DimensionNotesProps {
  phaseId: string;
  teamId: string;
  dimensionId: string;
  onToggle: (isOpen: boolean) => void;
  siblingRef: React.RefObject<HTMLDivElement>;
  score: number;
  clickedCriteria: string[];
}

const mapStateToProps = (state: Map<string, any>, ownProps: DimensionNotesProps) => ({
  notes: fromNotes.getDimensionNotes(state?.toJS(), {
    phaseId: ownProps.phaseId,
    teamId: ownProps.teamId,
    dimensionId: ownProps.dimensionId,
  }),
});

const connector = connect(mapStateToProps);

const DimensionNotes = ({
  onToggle,
  phaseId,
  teamId,
  dimensionId,
  notes,
  siblingRef,
  score,
  clickedCriteria,
}: DimensionNotesProps & ConnectedProps<typeof connector>) => {
  const previousScore = useRef(score);
  const containerRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isOpen, _, __, toggle] = useBooleanFlag(false);
  const [isCommentFormShown, showCommentForm, hideCommentForm] = useBooleanFlag(false);
  const { height: siblingHeight } = useResizeObserver(siblingRef.current!!);
  const { width: parentElementWidth } = useResizeObserver(containerRef.current?.parentElement!!);

  // 430px is the min width for the dimension
  let width = Math.floor(isOpen ? parentElementWidth - 430 : 0);
  // 500px is the min height for comments secion when open, when closed is the sibling height + 1.65rem
  let height = `calc(${Math.floor(siblingHeight)}px + ${isOpen ? 1.5 : 1.65}rem)`;
  height = isOpen ? `max(${height}, 400px)` : height;

  const handleToggle = () => {
    if (isOpen) hideCommentForm();
    toggle();
    // wait a bit to make sure the animation has time to complete
    setTimeout(() => onToggle(!isOpen), 150);
  };

  // Close dimension notes when clicking on the sibling element
  useEffect(() => {
    const handleClick = () => {
      if (isOpen) handleToggle();
    };

    if (siblingRef.current && isOpen) {
      siblingRef.current.addEventListener('click', handleClick);
    } else if (siblingRef.current) {
      siblingRef.current.removeEventListener('click', handleClick);
    }

    return () => {
      siblingRef.current && siblingRef.current.removeEventListener('click', handleClick);
    };
  }, [siblingRef.current, isOpen]);

  // Open dimension notes when clicking on the score for first time
  useEffect(() => {
    if (!previousScore.current && !!score && !!clickedCriteria.length) {
      handleToggle();
      showCommentForm();
    }
    previousScore.current = score;
  }, [score, clickedCriteria]);

  return (
    <div
      ref={containerRef}
      style={{ height, width }}
      className={cx('bg-white02', Styles.DimensionNotes, {
        [Styles.Closed]: !isOpen,
        [Styles.Open]: isOpen,
      })}
    >
      <OverlayTrigger
        placement="left"
        overlay={
          <Tooltip>
            <strong>{isOpen ? 'Collapse' : 'Leave a comment'}</strong>
          </Tooltip>
        }
      >
        <Button
          id={`dimension-${dimensionId}-notes-toggle`}
          variant="secondary"
          className={Styles.Button}
          onClick={handleToggle}
        >
          <i
            className={cx('fa-solid', {
              'fa-message-plus': !isOpen,
              'fa-arrow-right-from-line': isOpen,
            })}
          />
        </Button>
      </OverlayTrigger>
      <div className={Styles.ContentWrapper} id={`dimension-${dimensionId}-notes`}>
        <div className={cx(Styles.Content, 'd-flex flex-column h-100')} style={{ width }}>
          <div className="flex-grow-1 p-2 overflow-auto">
            <NotesList notes={notes} phaseId={phaseId} teamId={teamId} compact emptyLabel="" />
          </div>
          <div className="bg-blue07 px-4 py-3">
            <AddNote
              initialShow={isCommentFormShown}
              phaseId={phaseId}
              teamId={teamId}
              dimensionId={dimensionId}
              isOrganizerScreen={false}
              compact
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connector(DimensionNotes);
