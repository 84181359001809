import { Alert, Col, Form, Row } from '@valid-eval/shared-react-components';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Email from 'components/Form/Email';
import Input from 'components/Form/Input';
import { minLengthTrim, sanitizeText, text } from 'components/FormFields/validations';

import { TeamDraftPayload } from 'data/features/teamDraftTypes';
import { StepComponentProps } from '../types';
import StepContainer from './StepContainer';
import StepFooter from './StepFooter';
import useContactInfoForm, { ContactInfoFormType } from './useContactInfoForm';
import useSaveTeamDraft from './useSaveTeamDraft';
import useValidateTeamName from './useValidateTeamName';

type ContactInfoStepProps = StepComponentProps;

const ContactInfoStep = ({
  draft,
  event,
  isActive,
  onInfoChange,
  saving,
  user,
  onNoSession,
  onSubmitStep,
  isTeamMemberEditor,
}: ContactInfoStepProps) => {
  const { t } = useTranslation();
  const eventId = event?.id || '';
  const draftId = draft?.id || '';
  const primaryContact = draft?.members?.find((member) => member.team_primary) || user;
  const form = useContactInfoForm({ user, primaryContact, draft, isActive, onInfoChange });
  const validateTeamName = useValidateTeamName({ draftId, eventId, onNoSession });
  const saveTeamDraft = useSaveTeamDraft({ eventId, onNoSession });

  const handleSubmit = (data: ContactInfoFormType) => saveTeamDraft(data, true, onSubmitStep);

  const handleChange = (data: TeamDraftPayload) => saveTeamDraft(data, true, () => form.trigger());

  const debouncedSave = useCallback(
    debounce(() => form.handleSubmit(handleChange)(), 1250),
    [],
  );

  return (
    <FormProvider {...form}>
      <Form
        className="h-100"
        onSubmit={form.handleSubmit(handleSubmit)}
        onChange={debouncedSave}
        noValidate
      >
        <StepContainer
          content={
            <>
              <Alert variant="info">{t('auth.teams_sign_up.contact_info_alert')}</Alert>
              <Row>
                <Col sm={6} className="mb-3">
                  <Input
                    name="first_name"
                    label={t('auth.teams_sign_up.fields.contact_first_name')}
                    required
                    disabled
                  />
                </Col>
                <Col sm={6} className="mb-3">
                  <Input
                    name="last_name"
                    label={t('auth.teams_sign_up.fields.contact_last_name')}
                    required
                    disabled
                  />
                </Col>
                <Col sm={6} className="mb-3">
                  <Email
                    name="email"
                    label={t('auth.teams_sign_up.fields.contact_email')}
                    required
                    disabled
                  />
                </Col>
                <Col sm={6} className="mb-3">
                  <Input
                    name="name"
                    label={t('event.team_profile_name_label')}
                    required
                    format={sanitizeText}
                    rules={{
                      validate: { minLength: minLengthTrim(3), text, validateTeamName },
                    }}
                    disabled={!user?.id || (!!draft?.id && !isTeamMemberEditor)}
                  />
                </Col>
              </Row>
            </>
          }
          footer={
            (!draft?.id || isTeamMemberEditor) && (
              <StepFooter
                event={event}
                draft={draft}
                active={isActive}
                disabled={saving || form.formState.isSubmitting || !user?.id}
                loading={saving || form.formState.isSubmitting}
              />
            )
          }
        />
      </Form>
    </FormProvider>
  );
};

export default ContactInfoStep;
